import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Head = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="robots" content="noindex" />
      <link
        rel="stylesheet"
        type="text/css"
        href="../styles/fonts/font.css"
      ></link>
      <title>{`${props.title} _ ${data.site.siteMetadata.author}`}</title>
    </Helmet>
  )
}

export default Head
