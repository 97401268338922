import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import footerStyle from './footer.module.scss'

const Footer = () => {

  const data = useStaticQuery(graphql`
    query {
        site{
          siteMetadata {
            author,
            copyright
          }
        }
      }

    `)

  return (
    <footer className={footerStyle.content} >
      <p>Create by {data.site.siteMetadata.author}, © {data.site.siteMetadata.copyright}</p>
    </footer>
  )
}

export default Footer