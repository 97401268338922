import React from "react"
import { motion } from "framer-motion"
import Footer from "../components/Footer"
import layoutStyles from "./layout.module.scss"

const Layout = props => {
  const initialAnimation = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={initialAnimation}
      className={layoutStyles.container}
      transition={{ duration: 0.5 }}
    >
      <div className={layoutStyles.content}>{props.children}</div>
      <Footer />
    </motion.div>
  )
}

export default Layout
